import React from "react";
import { ABOUT_SECTION_SOCIAL_ICONS, useSocialIconClick } from "@Utils";
import { EventCard } from "@Templates/elementary/components";
import { ITemplate } from "@Templates/ITemplate";
import { Navbar } from "@Modules/common";
import { FooterSection } from "repoV2/features/CreatorPages/modules/FooterSection";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import {
    TEMPLATE_CONFIG,
    TEMPLATE_KEYS,
} from "repoV2/features/CreatorPages/CreatorPages.constants";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";
import styles from "./elementary.module.scss";

const Elementary = ({
    hostData,
    preview,

    // common to be used by sub-components in TemplateRoots
    navbarProps,
    aboutSectionProps,
    eventSectionsProps,
    footerSectionProps,
}: ITemplate.IProps): JSX.Element => {
    const { onSocialIconClick } = useSocialIconClick();

    const { socialLinks, shortDescription } = aboutSectionProps;

    const {
        onEventListingClick,
        eventListings,
        onShareClick: onShareEventClick,
    } = eventSectionsProps;

    const { cover_pic: coverPic, name } = hostData;
    const optimisedImageUrl = getOptimisedImageUrl({
        imageUrl: coverPic || "",
        fetchWidth: TEMPLATE_CONFIG[TEMPLATE_KEYS.ELEMENTARY].coverImage.width,
    });

    // If a redirect is required due to invalid host, the hostData will be empty.
    // To prevent rendering error, the Loading... is shown till the page redirects
    // TODO: Show a fancy spinner
    if (!hostData) return <h2>Loading...</h2>;

    return (
        <div className={styles.root}>
            <Navbar {...navbarProps} />
            <div className={styles.laconicContainer}>
                <div
                    className={styles.background}
                    style={{
                        ...(coverPic && {
                            backgroundImage: `url(${optimisedImageUrl})`,
                        }),
                    }}
                />
                <div className={styles.staticContainer}>
                    <div className={styles.flexGrow} />
                    <h1 className={styles.textPrimary}>{name}</h1>
                    <div className={styles.textSecondary}>
                        {shortDescription}
                        {preview ? (
                            <div className={styles.coverPreviewInfo}>
                                &lt;Your cover image will be shown as the
                                background&gt;
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.flexGrow} />

                    <div className={styles.iconContainer}>
                        {Object.entries(socialLinks).map(
                            ([socialKey, socialObject]) => {
                                const IconComponent =
                                    ABOUT_SECTION_SOCIAL_ICONS[
                                        socialKey as keyof typeof ABOUT_SECTION_SOCIAL_ICONS
                                    ];
                                return (
                                    <a
                                        key={socialKey}
                                        className={styles.icon}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={socialObject?.link}
                                        onClick={e => {
                                            e.preventDefault();
                                            onSocialIconClick(
                                                socialObject?.link
                                            );
                                        }}
                                    >
                                        <IconComponent />
                                    </a>
                                );
                            }
                        )}
                    </div>
                </div>
                <div
                    className={`${styles.eventContainer} ${
                        // Show the event card in the center if only single event
                        eventListings.length === 1 ? styles.singleEvent : ""
                    }`}
                >
                    <CustomCodeSection
                        position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
                    />
                    {eventListings.map(event => (
                        <EventCard
                            key={event.key}
                            eventData={event}
                            onEventListingClick={onEventListingClick}
                            onShareClick={onShareEventClick}
                        />
                    ))}
                    <CustomCodeSection
                        position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
                    />
                </div>
            </div>
            <FooterSection {...footerSectionProps} />
        </div>
    );
};

export default Elementary;
