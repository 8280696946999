import { RiShareLine } from "@react-icons/all-files/ri/RiShareLine";
import React, { useRef } from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { parseDateTime } from "@Utils/parseDateTime";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { IEventCard } from "./EventCard";
import styles from "./eventcard.module.scss";

const EventCard = ({
    eventData,
    onEventListingClick,
    onShareClick,
}: IEventCard.IProps): JSX.Element => {
    const {
        title,
        short_description: shortDescription,
        description = "",
        cover_image: coverImage,
        next_slot_time: nextSlotTime,
        metadata,
        // is_payment_disabled: isPaymentDisabled,
    } = eventData;

    const shareEl = useRef<HTMLDivElement>(null);

    const handleCardClick = (e: React.MouseEvent) => {
        if (shareEl?.current?.contains(e.target as Node)) {
            return;
        }
        onEventListingClick(eventData);
    };

    const isCardStrip = metadata?.card_label; // Whether to show a strip over the card

    return (
        <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => null}
            className={`${styles.root} ${
                isCardStrip ? styles.earlyBirdRoot : ""
            }`}
            onClick={handleCardClick}
        >
            {isCardStrip ? (
                <div
                    className={styles.earlyBird}
                    style={{
                        color: metadata?.card_label?.color,
                        backgroundColor: metadata?.card_label?.background,
                    }}
                >
                    {metadata?.card_label?.content}
                </div>
            ) : null}
            <div
                className={`${styles.left} ${
                    nextSlotTime ? "" : styles.shrinkLeft
                }`}
            >
                <div className={styles.duration}>
                    <b>
                        {nextSlotTime
                            ? parseDateTime(nextSlotTime, "MMM dd")
                            : " "}
                    </b>
                    <span>{nextSlotTime ? "onwards" : ""}</span>
                </div>
                <div
                    role="button"
                    tabIndex={-1}
                    onKeyDown={() => null}
                    className={classNames(
                        styles.shareButton,
                        nextSlotTime && styles.dragUp
                    )}
                    onClick={() => onShareClick(eventData)}
                    ref={shareEl}
                >
                    <RiShareLine />
                </div>
            </div>

            <div className={styles.center}>
                <div className={styles.title}>{title}</div>
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: description }}
                    className={styles.text}
                />
                <div className={styles.priceContainer}>
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: shortDescription || "",
                        }}
                        className={styles.price}
                    />
                </div>
            </div>
            <div className={styles.right}>
                <ExlyImage
                    fetchWidth={350}
                    src={coverImage}
                    loading="lazy"
                    className={styles.cardImage}
                    alt=""
                />
                <div className={styles.scheduleButton}>{eventData.ctaText}</div>
            </div>
        </div>
    );
};

export default EventCard;
